import React from "react";
import useBreakpoint from "../../hooks/useBreakpoints";
import ErrorStyles from "../css/ErrorMessage.module.css";
export default function ErrorMessageWrapper({ children, ...props }) {
	const [{ isSmall }, isMobile] = useBreakpoint();
	return (
		<div
			className={
				isMobile
					? ErrorStyles.mobilePlaceholder
					: ErrorStyles.basePlaceholder
			}
			style={props.style}
		>
			<div
				className={`${ErrorStyles.base} ${
					isMobile ? ErrorStyles.mobile : ""
				}`}
			>
				{children}
			</div>
		</div>
	);
}
