import React, { useState } from "react";

const LicenseContext = React.createContext();

/**
 * License context provider, consumer and hook module.
 * @module license/context
 * @category Context
 */

/**
 * License context provider
 * @function
 * @param {React.ReactChildren} children render all children
 */
export function LicenseProvider({ children }) {
	const [state, setState] = useState({});
	const value = { license: state, setLicense: setState };
	return (
		<LicenseContext.Provider value={value}>
			{children}
		</LicenseContext.Provider>
	);
}

/**
 * License context consumer
 * @category Context
 * @member LicenseConsumer
 * @function
 * @param {React.ReactChildren} children render all children
 */
export function LicenseConsumer({ children }) {
	return (
		<LicenseContext.Consumer>
			{(context) => {
				if (context === undefined) {
					throw new Error(
						"LicenseContext must be used within a LicenseProvider"
					);
				}
				return children(context);
			}}
		</LicenseContext.Consumer>
	);
}
/**
 * License hook
 * @member useLicense
 * @function
 * @example
 * // Define the license state and setter function
 * const { license, setLicense } = useLicense();
 * // or only one of them
 * const { license } = useLicense();
 * // Setter example
 * setLicense({...license, key: "somekey"});
 * 
 * // Licese data example
 * {
		key: "9vl7m0u1u6eb7ipqsi5ip1qr",
		domains: ["example.com", "google.com"],
		price: "1000",
		validFor: 365,
		productId: 11,
		customer: {
			exists: true,
			company: "Test Company",
			email: "License_9vl7m0u1u6eb7ipqsi5ip1qr@dev.nex5.com",
		},
	}
 */
export function useLicense() {
	const context = React.useContext(LicenseContext);
	if (context === undefined) {
		throw new Error("useLicense must be used within a LicenseProvider");
	}
	return context;
}
