import React from "react";
import { useLicense } from "../context/licenseData-context";
import { useLoading } from "../context/loading-context";
import useTemplate from "../hooks/useTemplate";
import styles from "./css/LicensePriceHeader.module.css";
import Placeholder from "./layout/Placeholder";

export default function LicensePriceHeader({ currency }) {
	const { license } = useLicense();
	const { isLoading } = useLoading();
	const template = useTemplate();
	return (
		<div className={styles.container}>
			{isLoading && (
				<Placeholder
					lines={[
						{
							width: "150px",
							height: "32px",
							borderRadius: "3px",
						},
					]}
				/>
			)}
			{!isLoading && (
				<h3 className={styles.title}>
					{license?.pricingPlanText
						? license.pricingPlanText
						: template.header.timeInterval + " Fee"}
				</h3>
			)}
			<div className={styles.priceContainer}>
				<span className={styles.price}>
					{isLoading && (
						<Placeholder
							lines={[
								{
									width: "150px",
									height: "42px",
									borderRadius: "3px",
								},
							]}
						/>
					)}
					{!isLoading && (
						<>
							{currency} {license.price}
						</>
					)}
				</span>
				{/* <div className={styles.priceInfo}>VAT Included</div> */}
			</div>
		</div>
	);
}
