import { Field } from "formik";
import React from "react";
import Checkbox from "./layout/Checkbox";
import FieldFeedback from "./forms/FieldFeedback";
import useTemplate from "../hooks/useTemplate";

export default function TermsAndConditions(props) {
	const containerStyle = {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		marginBottom: "2rem",
		position: "relative",
	};

    const template = useTemplate();
	
	return (
		<div>
			<p>{"Terms & Conditions"}</p>
			<div style={containerStyle}>
				<Field name="terms">
					{(field, form, meta) => (
						<>
							<Checkbox small {...field} name="terms">
								<p>
									I agree with the{" "}
									<a href={template.tos} target="_blank">
										Terms & Conditions
									</a>
								</p>
							</Checkbox>
						</>
					)}
				</Field>
				<FieldFeedback name="terms" style={{ marginLeft: ".7rem" }} />
			</div>
		</div>
	);
}
