import React, { useEffect, useRef, useState } from "react";
import Br_1Img from "../../assets/img/banner/Browsers_1.png";
import Br_2Img from "../../assets/img/banner/Browsers_2.png";
import DevicesImg from "../../assets/img/banner/Devices.png";
import LogoImg from "../../assets/img/banner/Logo.png";
import Styles from "./css/Banner.module.css";
import useTemplate from "../../hooks/useTemplate";

export default function Banner() {
	const [mouseIn, setMouseIn] = useState(null);
	const template = useTemplate();
	const mouseRef = useRef(mouseIn);
	const refDiv = useRef(null);
	const refImg = useRef(null);
	const refLogo = useRef(null);
	const refBr = useRef(null);
	let lastScroll = 0;
	let timer = null;
	useEffect(() => {
		window.addEventListener("mousemove", parallax);
		// window.addEventListener("scroll", scroll);
		return () => {
			window.removeEventListener("mousemove", parallax);
			// window.addEventListener("scroll", scroll);
		};
	}, []);

	function scroll(e) {
		var st = window.pageYOffset || document.documentElement.scrollTop;
		if (st > lastScroll) {
			refImg.current.style.transform = `translate(0%,-3%)`;
			refBr.current.style.transform = `translate(0%,-5%)`;
		} else {

			refImg.current.style.transform = `translate(0%,3%)`;
			refBr.current.style.transform = `translate(0%,5%)`;
		}
		if (timer !== null) {
			clearTimeout(timer);
		}
		timer = setTimeout(function () {
			refImg.current.style.transform = `translate(0%,0%)`;
			refBr.current.style.transform = `translate(0%,0%)`;
		}, 50);
		lastScroll = st <= 0 ? 0 : st; // For Mobile or negative scrolling
	}

	function parallax(e) {
		let tgtImg = refImg.current;
		let tgtBr = refBr.current;
		if (!mouseRef.current) {
			tgtImg.style.transform = `translate(0)`;
			tgtBr.style.transform = `translate(0)`;
			return;
		}
		let elem = refDiv.current;
		let pos = elem.getBoundingClientRect();
		let { x, y, width, height } = pos;
		let _w = x + width / 2;
		let _h = y + height / 2;
		let _mouseX = e.clientX;
		let _mouseY = e.clientY;
		let _d1 = `${-(_mouseX - _w) * 0.005}%, ${-(_mouseY - _h) * 0.005}%`;
		let _d2 = `${-(_mouseX - _w) * 0.02}%, ${-(_mouseY - _h) * 0.02}%`;
		tgtImg.style.transform = `translate(${_d1})`;
		tgtBr.style.transform = `translate(${_d2})`;
	}

	function onMouse(e) {
		let isIn = e.type === "mouseenter";
		mouseRef.current = isIn;
		setMouseIn(isIn);
	}

	const parallaxStyle = {
		transform: "translate(0)",
		transition: "0.2s linear",
	};

	return (
		<div
			className={Styles.container + " nex-fadein"}
			onMouseEnter={onMouse}
			onMouseLeave={onMouse}
			ref={refDiv}
		>
			<img
				src={LogoImg}
				alt="logo"
				className={Styles.logo + " nex-fadein-delayed"}
				ref={refLogo}
				style={{ ...parallaxStyle }}
			/>
			<img
				src={DevicesImg}
				alt="devices"
				ref={refImg}
				className={Styles.devices}
				style={parallaxStyle}
				style={{ ...parallaxStyle }}
			/>
			<div
				className={Styles.brContainer}
				ref={refBr}
				style={{
					...parallaxStyle,
					...{ height: "160px" },
					...{ opacity: template.banner.browsersVisible ? 1 : 0 },
				}}
			>
				{template.banner.browsersVisible && (
					<img
						src={Br_1Img}
						alt="browsers-1"
						className={Styles.br1}
					/>
				)}
				{template.banner.browsersVisible && (
					<img
						src={Br_2Img}
						alt="browsers-2"
						className={Styles.br2}
					/>
				)}
			</div>
		</div>
	);
}
