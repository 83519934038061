import React from "react";

/**
 * Hooks in the application.
 * @module useWindowSize
 * @category Hooks
 */

/**
 * Window size hook. Provides the width and height of the current window. Used by the useBreakpoint() hook.
 * @category Hooks
 * @function
 * @returns {object} width and height attributes.
 * @example
 * // You can get width and height (in px):
 * const { width, height } = useWindowSize();
 * console.log(width)  // "792px"
 * console.log(height) // "1800px"
 */
const useWindowSize = () => {
	const isSSR = typeof window === "undefined";
	const [windowSize, setWindowSize] = React.useState({
		width: isSSR ? 1200 : window.innerWidth,
		height: isSSR ? 800 : window.innerHeight,
	});

	function changeWindowSize() {
		setWindowSize({ width: window.innerWidth, height: window.innerHeight });
	}

	React.useEffect(() => {
		window.addEventListener("resize", changeWindowSize);

		return () => {
			window.removeEventListener("resize", changeWindowSize);
		};
	}, []);

	return windowSize;
}


export default useWindowSize;