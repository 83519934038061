import React from "react";
import { Field } from "formik";
import Collapsible from "../layout/Collapsible";
import Label from "../layout/Label";
import {
	CountrySelect,
	FieldGroup,
	RegionSelect,
	Styles as InputStyles,
	TextInput,
} from "../layout/Input";
import FieldFeedback from "./FieldFeedback";
/* 
    Second step of the form
    - VAT Number
    - Country (Dropdown)
    - City
    - Postal Code
*/
const BillingDetailsForm = ({ open, _toggle, values, handleChange }) => {
	return (
		<Collapsible
			visible={open}
			title="Billing Details"
			onToggle={() => _toggle("billingDetails")}
		>
			<Label text="VAT Number" />
			<Field
				type="text"
				className={InputStyles.base}
				autoComplete="off"
				width="100%"
				mobileWidth="100%"
				name="vatNumber"
				placeholder="VAT Number"
				component={TextInput}
				value={values?.vatNumber}
				onChange={handleChange}
			>
				<FieldFeedback name="vatNumber" />
			</Field>
			<Label text="Location Information" />
			<Field
				type="text"
				className={InputStyles.base}
				autoComplete="address-line1"
				style={{ width: "50%", position: "relative" }}
				name="address"
				placeholder="Address"
				component={TextInput}
				value={values?.address}
				onChange={handleChange}
			>
				<FieldFeedback name="address" />
			</Field>
			<Field
				className={InputStyles.base}
				autoComplete="country"
				width="100%"
				mobileWidth="100%"
				name="country"
				component={CountrySelect}
				placeholder="Country"
				value={values?.country}
				onChange={handleChange}
				classes={
					values?.country && values?.country !== ""
						? ""
						: InputStyles.placeholder
				}
			>
				<FieldFeedback name="country" />
			</Field>
			<FieldGroup>
				<Field
					className={InputStyles.base}
					autoComplete="address-level2"
					width="50%"
					mobileWidth="100%"
					name="region"
					country={values?.country}
					component={RegionSelect}
					placeholder="City"
					value={values?.region}
					classes={
						values?.region && values?.region !== ""
							? ""
							: InputStyles.placeholder
					}
					onChange={handleChange}
				>
					<FieldFeedback name="region" />
				</Field>
				<Field
					type="text"
					className={InputStyles.base}
					autoComplete="postal-code"
					width="50%"
					mobileWidth="100%"
					name="postalCode"
					placeholder="Postal Code"
					component={TextInput}
					value={values?.postalCode}
					onChange={handleChange}
				>
					<FieldFeedback name="postalCode" />
				</Field>
			</FieldGroup>
		</Collapsible>
	);
};

export default BillingDetailsForm;
