import React from "react";
import GrayBox from "./layout/GrayBox";
import Styles from "./css/LicenseDetails.module.css";
import { BsExclamationCircle } from "react-icons/bs";
import {
	addDays,
	capitalizeFirstLetter,
	flattenObj,
	formatDomains,
	htmlDecode,
} from "../utils/utils";
import { useLicense } from "../context/licenseData-context";
import { useLoading } from "../context/loading-context";
import Placeholder from "./layout/Placeholder";
import useTemplate from "../hooks/useTemplate";
import useBreakpoint from "../hooks/useBreakpoints";
import { getDisplayName } from "../data/platforms";
import { getDateFormatted } from "../utils/dateFormat";

export default function LicenseDetails({ format = Formats.base }) {
	const { license } = useLicense();
	const { isLoading } = useLoading();
	const template = useTemplate();
	let expirationDate = new Date();
	let expDateFormatted = expirationDate.toString();

	if (license?.validFor) {
		expirationDate = addDays(expirationDate, parseInt(license?.validFor));
		expDateFormatted = getDateFormatted(expirationDate);
	}

	if (isLoading) {
		return (
			<Placeholder
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
				lines={[
					{ width: "30%", height: "16px", margin: "18px 0 0 0" },
					{ width: "20%", height: "16px", margin: "18px 0 0 0" },
					{ width: "25%", height: "16px", margin: "18px 0 0 0" },
					{ width: "20%", height: "16px", margin: "18px 0 30px 0" },
				]}
			/>
		);
	}

	return (
		<>
			<GrayBox
				style={{ marginBottom: "25px", padding: "1rem" }}
				className={Styles.allBold}
			>
				{license?.details && (
					<>
						{template.licenseDetails.validFor.visible && (
							<p style={{ textAlign: "center", fontWeight: 500 }}>
								Expires On: {expDateFormatted}
							</p>
						)}
						<div
							dangerouslySetInnerHTML={{
								__html: htmlDecode(license.details),
							}}
						></div>
					</>
				)}
				{!license?.details && (
					<DefaultData
						type={format}
						license={license}
						expDateFormatted={expDateFormatted}
					/>
				)}
			</GrayBox>
			{/* <p style={infoStyles}>
				<BsExclamationCircle style={{ marginRight: "0.75rem" }} /> This
				license will include an unlimited number of impressions and all
				subdomains.
			</p> */}
		</>
	);
}

export const Formats = {
	base: "base",
	alt: "alt",
};

const DefaultData = ({ type = Formats.base, license, expDateFormatted }) => {
	const template = useTemplate();
	let { domains } = license;
	let domainPlural = false;
	if (domains) {
		let lgth = Object.keys(domains).length;
		if (lgth > 1) domainPlural = true;
	}

	switch (type) {
		case Formats.base:
			return (
				<div
					style={{
						textAlign: "center",
						padding: "0.25rem 0 0.25rem 0",
					}}
				>
					{license?.key && (
						<>
							<p style={{ marginBottom: "10px" }}>
								<b>License Key</b>
							</p>
							<p>{license.key}</p>
						</>
					)}

					{/* <p style={{ color: "var(--secondary)" }}>
						Unlimited number of impressions
					</p> */}
					<p
						style={{
							color: "var(--secondary)",
							marginBottom: "10px",
							display: template.header.validFor.visible
								? "block"
								: "none",
						}}
					>
						{" "}
						Valid for{" "}
						{license.validFor === 365 || license.validFor === "365"
							? "1 year"
							: license.validFor + " days"}{" "}
					</p>
					<div>
						<p>
							{domainPlural
								? template.licenseData.domain.plural
								: template.licenseData.domain.singular}{" "}
							to be activated:
						</p>
						<p style={{ color: "var(--secondary)" }}>
							{formatDomains(license.domains)}
						</p>
					</div>
				</div>
			);
		case Formats.alt:
			return (
				<div
					style={{
						textAlign: "center",
						fontWeight: "normal",
						display: "flex",
						justifyContent: "center",
						flexDirection: "column",
						gap: "0.5rem",
						padding: "1rem 0",
					}}
				>
					{template.name === template.versions.html5 && (
						<>
							<span style={{ fontWeight: 500 }}>License key</span>
							<span
								style={{
									color: "var(--secondary)",
									marginBottom: "10px",
								}}
							>
								{license.key}
							</span>
						</>
					)}
					<span
						style={{
							fontWeight: 500,
							display: template.licenseDetails.validFor.visible
								? "block"
								: "none",
						}}
					>
						Expires On: {expDateFormatted}
					</span>
					{/* <span
						style={{
							color: "var(--secondary)",
							marginBottom: "10px",
							display: template.licenseDetails.validFor.visible
								? "block"
								: "none",
						}}
					>
						
					</span> */}
					{/* <span style={{ fontWeight: 500 }}>
						{domainPlural
							? template.licenseDetails.domain.plural
							: template.licenseDetails.domain.singular}{" "}
						to be activated
					</span> */}
					<span style={{ color: "var(--secondary)" }}>
						{template.name === template.versions.html5 &&
							formatDomains(license.domains)}
						{template.name !== template.versions.html5 && (
							<PlatformTable domains={license.domains} />
						)}
					</span>
				</div>
			);

		default:
			return <></>;
	}
};

const PlatformTable = ({ domains = {} }) => {
	let entries = flattenObj(domains);
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			{entries.map(({ key, value }, i, arr) => (
				<PlatformTableRow
					key={key + value + i.toString()}
					platform={value}
					value={key}
					isLast={i === arr.length - 1}
				/>
			))}
		</div>
	);
};

const PlatformTableRow = ({ platform, value, isLast = false }) => {
	let rowContent;
	let isAsterisk = value === "*";
	let platformName = getDisplayName(platform);
	if (isAsterisk) {
		rowContent = (
			<div
				style={{
					width: "100%",
					color: "black",
					textAlign: "center",
					maxWidth: "100%",
					wordBreak: "break-word",
				}}
			>
				{platformName === "Windows" && (
					<>Unreal License for one {getDisplayName(platform)} App</>
				)}
				{platformName !== "Windows" && (
					<>One {getDisplayName(platform)} App ID</>
				)}
			</div>
		);
	} else {
		rowContent = (
			<div
				style={{
					width: "100%",
					textAlign: "center",
					maxWidth: "100%",
					wordBreak: "break-word",
				}}
			>
				<span style={{ color: "black" }}>
					{getDisplayName(platform)}
				</span>{" "}
				{value}
			</div>
		);
	}
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				width: "90%",
				maxWidth: "90%",
				...(!isLast &&
					!isAsterisk && {
						borderBottom: "1px solid #e1e1e1",
					}),
				padding: isAsterisk ? "0.33rem" : "0.75rem 0",
				// gap: "1.75rem",
			}}
		>
			{rowContent}
		</div>
	);
};
