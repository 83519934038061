import React from "react";
import { useLoading } from "../../context/loading-context";
import useTemplate from "../../hooks/useTemplate";
import Placeholder from "./Placeholder";

export default function Header({ className, children }) {
	const containerStyles = {
		textAlign: "center",
		marginBottom: "25px",
	};

	const titleStyles = {
		margin: "0 0 25px 0",
		fontSize: "30px",
		fontWeight: 500,
		lineHeight: "42px",
		letterSpacing: "0em",
		textAlign: "center",
	};

	const subtitleStyles = {
		margin: "0 0 1rem 0",
		fontSize: "13px",
		fontWeight: 500,
		lineHeight: "18.2px",
		letterSpacing: "0em",
		textAlign: "center",
	};

	const template = useTemplate();
	const { isLoading } = useLoading();

	return (
		<div className={className} style={containerStyles}>
			{/* {isLoading && <Placeholder
				style={titleStyles}
				lines={[
					{ width: "30%", height: "16px", margin: "50px" }
				]}
			/>} */}

			<h1 style={titleStyles}>
				{!isLoading && template.header.title}
				{isLoading && (
					<Placeholder
						style={{
							display: "inline-block",
							marginBottom: "-7px",
						}}
						lines={[{ width: "28rem", height: "2.7rem", margin: 0 }]}
					/>
				)}
			</h1>
			{/* <h4 style={subtitleStyles}>Let's finish powering you up!</h4> */}
			{children}
		</div>
	);
}
