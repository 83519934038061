import React from "react";
import {
	BsFillExclamationCircleFill,
	BsCheck,
	BsArrowRepeat,
} from "react-icons/bs";
import styles from "./css/Button.module.css";
import classNames from "classnames";

export const ButtonStates = {
	error: "error",
	success: "success",
	warning: "warning",
	loading: "loading",
};

export function Button({
	state = "",
	variant = "primary",
	children,
	onClick = () => {},
	...other
}) {
	const stateIcon = (state, style) => {
		switch (state) {
			case ButtonStates.loading:
				return <BsArrowRepeat style={style} className="nex-spin" />;

			case ButtonStates.success:
				return <BsCheck style={style} />;

			case ButtonStates.error:
				return <BsFillExclamationCircleFill style={style} />;

			case ButtonStates.warning:
				return <BsFillExclamationCircleFill style={style} />;

			default:
				break;
		}
	};

	return (
		<button
			className={classNames(styles.base, {
				[styles.error]: state === ButtonStates.error,
				[styles.success]: state === ButtonStates.success,
				[styles.warning]: state === ButtonStates.warning,
				[styles.primary]: variant === "primary",
				[styles.secondary]: variant === "secondary",
			})}
			onClick={(e) => {
				if (state !== ButtonStates.loading) {
					onClick(e);
				}
			}}
			{...other}
		>
			<div
				style={{
					minHeight: "16px",
					minWidth: "16px",
					marginRight: "6px",
					display: state ? "flex" : "none",
					justifyContent: "stretch",
					alignItems: "stretch",
				}}
			>
				{stateIcon(state, { width: "100%", height: "100%" })}
			</div>
			<div style={{ textAlign: "center", flexGrow: 1 }}>{children}</div>
		</button>
	);
}
