import React from "react";

export default function Label({
	htmlFor = "",
	style = {},
	text = "",
}) {
	return (
		<label htmlFor={htmlFor} style={style}>
			<p>{text}</p>
		</label>
	);
}
