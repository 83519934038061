import React, { useEffect } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import styles from "./css/ThankYou.module.css";
import LicenseDetails, {
	Formats as DetailFormats,
} from "../components/LicenseDetails";
import { useLicense } from "../context/licenseData-context";
import { Redirect } from "react-router";
import { usePayment } from "../context/payment-context";
import SideBarLayout from "../components/layout/SideBarLayout";
import Banner from "../components/layout/Banner";

/**
 * Thank you view for customer after checkout, shows the license information as well as the expiration date of the license and domain information.
 * @component ThankYou
 * @category Views
 */
function ThankYou() {
	const { license, setLicense } = useLicense();
	const { dispatch: paymentDispatch } = usePayment();

	useEffect(() => {
		return () => {
			paymentDispatch({ type: "clear" });
			setLicense(false);
			process.env.REACT_APP_DEV_MODE === "true" && console.log("Clear");
		};
		// eslint-disable-next-line
	}, []);

	if (!license || Object.keys(license).length === 0) {
		return <Redirect to="/" />;
	}

	return (
		<SideBarLayout
			sidebar={<Banner />}
			barWidth={"400px"}
			padding={{
				top: "95px",
				right: "120px",
				bottom: "95px",
				left: "120px",
			}}
		>
			<div className={styles.container}>
				<h1 className={styles.title}>
					Your payment has been approved!
				</h1>
				<h4 className={styles.subtitle}>Thank you for choosing us</h4>
				<FaRegCheckCircle
					style={{ width: "73px", height: "auto", fill: "#35528D" }}
				/>
				<h2 className={styles.details}>
					Check your inbox for your license's details
				</h2>
			</div>
			<LicenseDetails format={DetailFormats.alt} />
		</SideBarLayout>
	);
}

export default ThankYou;
