
export function getDisplayName(plat){
    const names = {
        html5: "HTML5",
        unity: "Unity",
        android: "Android",
        ios: "iOS",
        webos: "webOS",
        tizen: "Tizen",
        nintendo: "Nintendo",
        xbox: "Xbox",
        ps4: "PS4",
        ps5: "PS5",
        webgl: "webGL",
        windows: "Windows",
    }

    return names[plat];
}