import React from "react";
import { BsArrowRepeat } from "react-icons/bs";

export default function Spinner({ style, className = "", ...props }) {
	return (
		<BsArrowRepeat
			style={style}
			{...props}
			className={"nex-spin " + className}
		/>
	);
}
