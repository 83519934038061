import classNames from "classnames";
import React, { Fragment } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import styles from "../css/Collapsible.module.css";

export default function Collapsible({
	visible = true,
	title,
	onToggle,
	children,
	...props
}) {
	return (
		<Fragment>
			<div
				className={classNames(styles.base, {
					[styles.active]: visible,
				})}
				onClick={onToggle}
				{...props}
			>
				{title}
				{!visible && <BsChevronDown />}
				{visible && <BsChevronUp />}
			</div>
			<div
				className={classNames(styles.content, {
					[styles.active]: visible,
				})}
			>
				{children}
			</div>
		</Fragment>
	);
}
