import React from "react";

export default function GrayBox({ style = {}, children, ...props }) {
	const styles = {
		backgroundColor: "var(--light-grey)",
		borderRadius: "19px",
	};

	return (
		<div style={{ ...styles, ...style }} {...props}>
			{children}
		</div>
	);
}
