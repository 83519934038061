import { ErrorMessage } from "formik";
import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import ErrorMessageWrapper from "../layout/ErrorMessageWrapper";
export default function FieldFeedback(props) {
	const getErrorMsg = (msg) => {
		let displayMessage,
			icon = <AiOutlineInfoCircle />;
		switch (msg) {
			case "Required":
				displayMessage = "This field is required";
				break;
			default:
				displayMessage = msg;
				break;
		}
		return (
			<>
				{icon && <span style={{ marginRight: "6px" }}>{icon}</span>}
				{displayMessage}
			</>
		);
	};

	return (
		<ErrorMessageWrapper style={props.style}>
			<ErrorMessage render={getErrorMsg} {...props} />
		</ErrorMessageWrapper>
	);
}
