import React from "react";
import { Field } from "formik";
import Label from "../layout/Label";
import Collapsible from "../layout/Collapsible";
import { Styles as InputStyles, TextInput } from "../layout/Input";
import FieldFeedback from "./FieldFeedback";

/* 
    First step of the form
    - Full Name
    - Company Name
    - Address
    - Email
*/
const CustomerDetailsForm = ({ open, _toggle, handleChange, values }) => {

	return (
		<Collapsible
			visible={open}
			title="Customer Details"
			onToggle={() => {
				_toggle("customerDetails");
			}}
			style={{}}
		>
			{/* <Label
				text="Please make sure the data below is correct"
				style={{ color: "var(--blue)" }}
			/> */}
			<Field
				type="text"
				autoComplete="name"
				className={InputStyles.base}
				style={{ width: "100%", position: "relative" }}
				name="fullName"
				placeholder="Full Name"
				component={TextInput}
			>
				<FieldFeedback name="fullName" />
			</Field>

			<Label text="Company Information" />
				<Field
					type="text"
					className={InputStyles.base}
					autoComplete="organization"
					style={{ width: "50%", position: "relative" }}
					name="companyName"
					placeholder="Company Name"
					component={TextInput}
					value={values?.companyName}
					onChange={handleChange}
				>
					<FieldFeedback name="companyName" />
				</Field>
			<Label text="Email" htmlFor="email" />
			<Field
				type="text"
				className={InputStyles.base}
				autoComplete="email"
				style={{ width: "100%", position: "relative" }}
				name="email"
				placeholder="someone@example.com"
				component={TextInput}
				value={values?.email}
				onChange={handleChange}
			>
				<FieldFeedback name="email" />
			</Field>
		</Collapsible>
	);
};

export default CustomerDetailsForm;
