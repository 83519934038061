import React, { useEffect } from "react";
import Img from "../assets/img/ErrorShip.png";
import Styles from "./css/Error.module.css";
import { Button } from "../components/layout/Button";
import { Link, useLocation } from "react-router-dom";
import { usePayment } from "../context/payment-context";
import { useLicense } from "../context/licenseData-context";

/**
 * Error page for redirecting when an issue occurs on the page.
 * @component Error
 * @category Views
 */
function Error() {
	const location = useLocation();
	const { dispatch: paymentDispatch } = usePayment();
	const { setLicense } = useLicense();
	useEffect(() => {
		paymentDispatch({ type: "clear" });
		setLicense(false);
		// eslint-disable-next-line
	}, []);
	return (
		<div className={Styles.container}>
			{!location?.state?.title && (
				<img src={Img} alt="error-sapceship" className={Styles.img} />
			)}
			<h3 className={Styles.title}>
				{!location?.state?.title && "Whoops! Something went wrong"}
				{location?.state?.title && location?.state?.title}
			</h3>
			<p className={Styles.subtitle}>
				{!location?.state?.message &&
					"We are working to fix the problem. Please try again later."}
				{location?.state?.message && location?.state?.message}
			</p>
			<Link to="/" style={{ textDecoration: "none" }}>
				<Button
					type="submit"
					style={{
						width: "300px",
						height: "50px",
						borderRadius: "16px",
						margin: "auto",
					}}
				>
					<span style={{ margin: "auto" }}>Home</span>
				</Button>
			</Link>
		</div>
	);
}

export default Error;
