/**
 * @module data/layout
 */

/**
 * @var windowBreakPoints
 * @description Object with the window size break points.
 * @property {number} xsmall Smallest devices (<576px)
 * @property {number} small Mobile devices
 * @property {number} medium Tablets, medium sized devices
 * @property {number} large Desktop and Laptops devices
 * @property {number} xlarge 1440p and up screen devices
 */
export const windowBreakPoints = {
    xsmall: 576,
    small: 798,
    medium: 992,
    large: 1200,
    xlarge: 1400
}