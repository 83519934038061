import React from "react";
import styles from "./css/Placeholder.module.css";

function Placeholder({ lines, ...args }) {
	return (
		<div {...args}>
			{lines &&
				lines.map(({ width, ...oth }, idx) => (
					<div
						key={"placeholder_" + idx.toString()}
						className={styles.loading}
						style={{ width, height: "10px", ...oth }}
					></div>
				))}
		</div>
	);
}

export default Placeholder;
