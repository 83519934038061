const ordinalSuffixes = {
	one: "st",
	two: "nd",
	few: "rd",
	other: "th",
};

function withOrdinalSuffix(x) {
	const ordinalPluralRules = new Intl.PluralRules("en", { type: "ordinal" });
	if (typeof x != "number")
		throw new TypeError(`Expected Number but received ${typeof x}`);
	if (x < 1) throw new RangeError(`Expected a number > 0 but received ${x}`);
	const ordinal = ordinalPluralRules.select(x);
	if (!ordinal in ordinalSuffixes)
		throw new Error(`Unexpected ordinal ${ordinal}`);
	const suffix = ordinalSuffixes[ordinal];
	return `${x}${suffix}`;
}

function getDateFormatted(date) {
	const fullFormat = new Intl.DateTimeFormat("en", {
		weekday: "long",
		day: "numeric",
		month: "long",
		year: "numeric",
	});
	const parts = fullFormat.formatToParts(date);

	const weekDayName = parts.find((p) => p.type === "weekday").value;
	const dayName = parts.find((p) => p.type === "day").value;
	const yearName = parts.find((p) => p.type === "year").value;
	const monthName = parts.find((p) => p.type === "month").value;

	const dayWithSuffix = withOrdinalSuffix(Number(dayName));

	return `${monthName} ${dayWithSuffix}, ${yearName}`;
}

export { getDateFormatted };
