import React, { useState } from "react";

const LoadingContext = React.createContext();

/**
 * Loading context provider, consumer and hook module.
 * @module loading/context
 * @category Context
 */

/**
 * Loading provider component
 * @category Context
 * @function
 * @param {React.ReactChildren} children Children components to render
 */
export function LoadingProvider({ children }) {
	const [state, setState] = useState(false);
	const value = { isLoading: state, setIsLoading: setState };
	return (
		<LoadingContext.Provider value={value}>
			{children}
		</LoadingContext.Provider>
	);
}

/**
 * Loading provider consumer
 * @category Context
 * @function
 * @param {React.ReactChildren} children Children components to render
 */
export function LoadingConsumer({ children }) {
	return (
		<LoadingContext.Consumer>
			{(context) => {
				if (context === undefined) {
					throw new Error(
						"LoadingContext must be used within a LoadingProvider"
					);
				}
				return children(context);
			}}
		</LoadingContext.Consumer>
	);
}

/**
 * Loading context hook
 * @category Context
 * @function
 */
export function useLoading() {
	const context = React.useContext(LoadingContext);
	if (context === undefined) {
		throw new Error("useLoading must be used within a LoadingProvider");
	}
	return context;
}
