import InputStyles from "./css/Input.module.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import useBreakpoint from "../../hooks/useBreakpoints";

export const Styles = InputStyles;

const StylizedContainer = ({
	form,
	field,
	className,
	mobileWidth,
	width,
	style,
	children,
}) => {
	const [{ isSmall }, isMobile] = useBreakpoint();
	const isLessThanMedium = isMobile;
	let addClassNames = "";
	if (form?.errors[field.name] && form?.touched[field.name]) {
		// We have some errors
		addClassNames = InputStyles.error;
	}
	return (
		<div
			className={`${className} ${addClassNames}`}
			style={{ ...style, width: isLessThanMedium ? mobileWidth : width }}
		>
			{children}
		</div>
	);
};

export const TextInput = ({
	children,
	...props
}) => {
	const { className = Styles.base, width, mobileWidth = "100%", style, ...rest } = props;
	return (
		<StylizedContainer
			className={className}
			field={rest.field}
			form={rest.form}
			width={width}
			style={style}
			mobileWidth={mobileWidth}
		>
			<input {...rest.field} {...rest} />
			{children}
		</StylizedContainer>
	);
};

export const FieldGroup = ({ style, children }) => {
	const [{ isSmall }, isMobile] = useBreakpoint();
	return (
		<div
			style={{
				display: "flex",
				flexDirection: isMobile ? "column" : "row",
				justifyContent: "center",
				alignItems: "stretch",
				...style,
			}}
		>
			{children}
		</div>
	);
};

export const CountrySelect = ({
	placeholder,
	onChange,
	children,
	...props
}) => {
	const { className = Styles.base, width, mobileWidth = "100%", style, ...rest } = props;
	return (
		<StylizedContainer
			className={className}
			field={rest.field}
			form={rest.form}
			width={width}
			style={style}
			mobileWidth={mobileWidth}
		>
			<CountryDropdown
				defaultOptionLabel={placeholder}
				value={rest.field?.value}
				showDefaultOption={true}
				onChange={(val) => {
					onChange(rest?.field.name)(val);
				}}
				{...rest}
			/>
			{children}
		</StylizedContainer>
	);
};

export const RegionSelect = ({
	country,
	placeholder,
	onChange,
	children,
	...props
}) => {
	const { className = Styles.base, width, mobileWidth = "100%", style, ...rest } = props;
	return (
		<StylizedContainer
			className={className}
			field={rest.field}
			form={rest.form}
			width={width}
			style={style}
			mobileWidth={mobileWidth}
		>
			<RegionDropdown
				country={country}
				value={rest.field?.value}
				onChange={(val) => {
					onChange(rest.field.name)(val);
				}}
				showDefaultOption={true}
				blankOptionLabel={placeholder}
				{...rest}
			/>
			{children}
		</StylizedContainer>
	);
};
