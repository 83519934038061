import paymentCards from "../../assets/img/supportedPayments.jpg" 

const paypal = {
	title: "PayPal",
	description:
		"",
};

const creditCard = {
	title: "Credit Card",
	description:
		<img src={paymentCards} alt="Supported Payments" />
};

const PaymentMethods = [paypal, creditCard];

export default PaymentMethods;
