import styles from "../css/PaymentOption.module.css";
import paymentMethods from "../../data/payments/PaymentMethods";
import Checkbox from "./Checkbox";
import { useRef } from "react";

export default function PaymentOption({
	title,
	description,
	onSelect,
	selected,
	img,
	imgWidth = "100px",
	imgHeight = "100%",
}) {

	// const ref = useRef(null);

	const onChange = (e) => {
		const newValue = e.target.value;
		const newSelected = paymentMethods.filter(
			(e) => e.title === newValue
		)[0];
		onSelect(newSelected);
	};

	return (
		<div className={styles.container}>
			<Checkbox onChange={onChange} selected={selected} value={title} />
			<div style={{ flexBasis: "70%" }}>
				<span className={styles.title}>{title}</span>
				{description && <div className={styles.description}>{description}</div>}
			</div>
			<div
				className={styles.container}
				style={{
					justifyContent: "center",
					flexBasis: "150px",
					margin: 0,
				}}
			>
				<img
					src={img}
					alt={title}
					width={imgWidth}
					height={imgHeight}
				/>
			</div>
		</div>
	);
}
