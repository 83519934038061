import { useLicense } from "../context/licenseData-context";
import { plans } from "../data/pricingPlans";
/**
 * Hooks in the application.
 * @module useTemplate
 * @category Hooks
 */

/**
 * Template hook, provides the fields and transoformations necessary when dealing with different platforms or versions
 * of the checkout page.
 * @category Hooks
 * @function
 * @returns {object}
 * @example
 * // You can get width and height (in px):
 * const { width, height } = useWindowSize();
 * console.log(width)  // "792px"
 * console.log(height) // "1800px"
 */
const useTemplate = () => {
	const { license } = useLicense();

	const versions = {
		html5: "html5",
		unity: "unity",
		unreal: "unreal",
	};

	const tos = {
		unity: "/terms-conditions-unity",
		html5: "/terms-conditions",
		unreal: "/terms-conditions-unreal",
	};

	let template = {
		versions,
		tos,
		banner: {
			browsersVisible: false,
		},
		header: {
			title: null,
			timeInterval: null,
		},
		licenseDetails: {
			domain: {
				singular: null,
				plural: null,
			},
			validFor: {
				visible: false,
			},
		},
		name: null,
	};

	switch (license?.template) {
		case versions.unity:
			template.header.title = "NexPlayer for Unity";
			template.licenseDetails.domain.singular = "Domain / App ID";
			template.licenseDetails.domain.plural = "Domains / App IDs";
			template.tos = tos.unity;
			break;

		case versions.unreal:
			template.header.title = "NexPlayer for Unreal";
			template.licenseDetails.domain.singular = "Domain / App ID";
			template.licenseDetails.domain.plural = "Domains / App IDs";
			template.tos = tos.unreal;
			break;

		case versions.html5:
			template.banner.browsersVisible = true;
			template.header.title = "NexPlayer HTML5 for Web";
			template.licenseDetails.domain.singular = "Domain";
			template.licenseDetails.domain.plural = "Domains";
			template.tos = tos.html5;
			break;

		default:
			break;
	}

	// Pricing plans
	switch (license?.pricingPlan) {
		case plans.oneTime:
			template.header.timeInterval = "One-month License";
			template.licenseDetails.validFor.visible = false;
			break;

		case plans.annual:
			template.header.timeInterval = "Annual";
			template.licenseDetails.validFor.visible = true;
			break;

		default:
			break;
	}

	template.name = license.template;

	return template;
};

export default useTemplate;
