import {
	FUNDING,
	PayPalButtons,
	usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import React from "react";
import { getCountryShortcode, objToUri, wpPost } from "../../utils/utils";
import Spinner from "../layout/Spinner";
import Styles from "./css/PayPalForm.module.css";
import GrayBox from "../layout/GrayBox";
import { usePayment } from "../../context/payment-context";
import { useFormikContext } from "formik";
import { useLicense } from "../../context/licenseData-context";

export default function PayPalForm() {
	const [{ isPending, isResolved }] = usePayPalScriptReducer();
	const { dispatch } = usePayment();
	const { license, setLicense } = useLicense();
	const { values, submitForm } = useFormikContext();

	// if (state?.payment?.error) {
	// 	dispatch({ type: "clear" });
	// 	return <Redirect push to="/error" />;
	// }

	return (
		<>
			<div className={Styles.container}>
				{isPending && (
					<GrayBox className={Styles.loaderContainer}>
						<Spinner className={Styles.loader} />
					</GrayBox>
				)}
				{isResolved && !values?.terms && (
					<GrayBox
						className={Styles.placeHolder}
						onClick={submitForm}
					></GrayBox>
				)}
				{isResolved && (
					<PayPalButtons
						fundingSource={FUNDING.PAYPAL}
						style={{ height: 41, color: "silver", shape: "rect" }}
						disabled={!values?.terms}
						createOrder={async () => {
							try {
								let res = await wpPost(
									"make_paypal_order",
									`key=${license.key}`
								).then((res) => res.json());
								if(!res || !res?.id){
									throw new Error("There was a problem contacting the servers, please try again later");
								}
								let val = await wpPost(
									"validate_order",
									objToUri({
										...values,
										...{
											country: getCountryShortcode(
												values.country
											),
											domains: license.domains,
											type: license.type, // Wether to create a new license or not
											intent: res?.id,
											checkoutType: "paypal",
										},
										...(license.hasOwnProperty("key") && {
											key: license?.key,
										}),
									})
								).then((val) => val.json());
								const { valid } = val;
								if (valid) {
									process.env.REACT_APP_DEV_MODE === "true" && console.log(val);
									return res?.id;
								} else {
									throw new Error(
										"Invalid order details, try again or contact us at support.madrid@nexplayer.com"
									);
								}
							} catch (err) {
								process.env.REACT_APP_DEV_MODE === "true" && console.error(err);
								dispatch({
									type: "exception",
									value: err,
								});
							}
						}}
						onApprove={(data, actions) => {
							return actions.order.capture().then(async (det) => {
								try {
									process.env.REACT_APP_DEV_MODE === "true" && console.log(
										"Captured transaction:",
										det,
										data
									);
									// Send confirm request
									let res = await wpPost(
										"confirm_paypal_order",
										objToUri({
											...values,
											...{
												country: getCountryShortcode(
													values.country
												),
												domains: license.domains,
												type: license.type, // Wether to create a new license or not
												intent: det.id,
												checkoutType: "paypal",
											},
											...(license.hasOwnProperty(
												"key"
											) && {
												key: license?.key,
											}),
										})
									);
									res = await res.json();
									const { key, error } = res;
									if (!key || error)
										throw new Error(
											"There was a problem with your license",
											error ? error : ""
										);
									setLicense({ ...license, ...{ key } });
									dispatch({ type: "capture", value: det });
								} catch (error) {
									process.env.REACT_APP_DEV_MODE === "true" && console.error(error.message);
									dispatch({
										type: "error",
										value: error,
									});
								}
							});
						}}
						onError={(err) => {
							process.env.REACT_APP_DEV_MODE === "true" && console.log(err);
							dispatch({ type: "exception", value: err });
						}}
					/>
				)}
			</div>
		</>
	);
}
