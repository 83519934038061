import React from "react";
import "./assets/css/App.css";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";
import DebugStats from "./components/internal/DebugStats";
import LicensePayment from "./views/LicensePayment";
import ThankYou from "./views/ThankYou";
import { Elements as StripeElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentProvider } from "./context/payment-context";
import { LicenseProvider } from "./context/licenseData-context";
import { LoadingProvider } from "./context/loading-context";
import Error from "./views/Error";
import CheckoutPage from "./views/CheckoutPage";

// Load the stripe engine
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const stripeOptions = {
	fonts: [
		{
			family: "Inter",
			src: "url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap)",
			weight: "500",
		},
	],
};

process.env.REACT_APP_DEV_MODE === "true" && console.debug("DEBUG MODE ACTIVE");

/**
 * Root component and routing of the application
 * @component App
 * @category General
 */
function App() {
	/**
	 * Check out page component group. Contains all components from layout. No logic within.
	 * @memberof App
	 */

	return (
		<Router basename="/buy-license">
			{process.env.REACT_APP_DEBUG_STATS === "true" && <DebugStats />}
			<LoadingProvider>
				<PaymentProvider>
					<LicenseProvider>
						<Switch>
							<Redirect from={process.env.PUBLIC_URL} to="/" />
							<Route path="/thank-you">
								<ThankYou />
							</Route>
							<Route path="/error">
								<Error />
							</Route>
							<Route path="/:licenseKey">
								<CheckoutPage>
									<StripeElements
										stripe={stripePromise}
										options={stripeOptions}
									>
										<LicensePayment />
									</StripeElements>
								</CheckoutPage>
							</Route>
							<Route
								path="/"
								component={() => {
									window.location.replace(
										"https://nexplayersdk.com/"
									);
									return null;
								}}
							/>
						</Switch>
					</LicenseProvider>
				</PaymentProvider>
			</LoadingProvider>
		</Router>
	);
}

export default App;
