import React from "react";
import styles from "./css/Checkbox.module.css";

export default function Checkbox({
	selected = false,
	value = "",
	small = false,
	onChange,
	children,
	field,
	ref,
	...props
}) {
	let containerStyle = {};
	if (children) {
		containerStyle = {
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "center",
		};
	}

	return (
		<div style={containerStyle}>
			<label
				className={`${styles.container} ${
					small ? styles.radioSmall : ""
				}`}
			>
				<input
					type="checkbox"
					onChange={field ? field?.onChange : onChange}
					value={field ? field?.value : value}
					checked={field ? null : selected}
					{...props}
				/>
				<span
					className={`${styles.border} ${
						small ? styles.radioSmall : ""
					}`}
				></span>
				<span
					className={`${styles.checkmark} ${
						small ? styles.radioSmall : ""
					}`}
				></span>
			</label>
			{children}
		</div>
	);
}
