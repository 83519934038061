import React from "react";
import useBreakpoint from "../../hooks/useBreakpoints";

function SideBarLayout({
	sidebar,
	barWidth = "150px",
	padding = {
		top: "0px",
		bottom: "0px",
		right: "0px",
		left: "0px",
	},
	mobileStyles = {
		padding: {
			top: "0px",
			bottom: "0px",
			right: "20px",
			left: "20px",
		},
	},
	children,
}) {
	const [{ isLarge }, isMobile] = useBreakpoint();

	const reducePaddingByOffset = (_padding, offset = 0.5) => {
		if(!_padding || typeof _padding !== "string") return;
		let rex = /[^\d]/g;
		let pUnit = _padding.match(rex);
		pUnit = pUnit.join("");
		let paddingLeft = parseFloat(_padding.replace(rex, ""));
		return (paddingLeft*offset).toString() + pUnit;
	}
	const isLessThanMedium = isMobile;

	const styles = {
		display: "grid",
		gridTemplateColumns: !isLessThanMedium
			? `[sidebar] ${barWidth} [content] 1fr`
			: `[content] 1fr`,
		maxWidth: "1400px",
		margin: "0 auto",
	};

	const sidebarStyles = {
		gridArea: "sidebar",
		position: "fixed",
		overflow: "hidden",
		maxWidth: barWidth,
	};

	const contentStyles = {
		gridArea: "content",
		paddingTop: isLessThanMedium ? mobileStyles?.padding?.top : padding?.top,
		paddingBottom: isLessThanMedium
			? mobileStyles?.padding?.bottom
			: padding?.bottom,
		paddingRight: isLessThanMedium ? mobileStyles?.padding?.right : padding?.right,
		paddingLeft: isLessThanMedium ? mobileStyles?.padding?.left : padding?.left,
	};
	
	/**
	 * Large fixes for clarity
	 */
	if (isLarge) {
		let paddingLeft = reducePaddingByOffset(padding?.left);
		let paddingRight = reducePaddingByOffset(padding?.right);
		if(paddingLeft) contentStyles.paddingLeft = paddingLeft;
		if(paddingRight) contentStyles.paddingRight = paddingRight;
	}

	if (!isLessThanMedium) {
		return (
			<div style={styles}>
				<div style={sidebarStyles}>{sidebar}</div>
				<div style={contentStyles}>{children}</div>
			</div>
		);
	} else {
		return (
			<div style={styles}>
				<div style={contentStyles}>{children}</div>
			</div>
		);
	}
}

export default SideBarLayout;	