import React from "react";
import Label from "../layout/Label";
import {
	CardNumberElement,
	CardCvcElement,
	CardExpiryElement,
} from "@stripe/react-stripe-js";
import TermsAndConditions from "../TermsAndConditions";
import PaymentOption from "../layout/PaymentOption";
import PaymentMethods from "../../data/payments/PaymentMethods";
import CCLogo from "../../assets/img/cc.svg";
import CCStyles from "../css/CCForm.module.css";
import PayPalLogo from "../../assets/img/paypal_logo.png";
import ErrorMessageWrapper from "../layout/ErrorMessageWrapper";
import { usePayment } from "../../context/payment-context";

/* 
    Handles the payment logic
*/

const PaymentForm = ({
	selectedPaymentOption,
	setSelectedPaymentOption,
	error,
}) => {
	const [paypal, creditCard] = PaymentMethods;
	const { dispatch } = usePayment();

	const onPaymentOptionSelect = (selected) => {
		setSelectedPaymentOption((previous) => {
			if (previous) {
				if (previous?.title !== selected.title) {
					return selected;
				} else {
					return previous;
				}
			} else {
				return selected;
			}
		});
		if (selectedPaymentOption !== selected) {
			dispatch({ type: "clear" });
		}
	};

	return (
		<div>
			<p style={{marginBottom: "2rem"}}>Select a payment method</p>
			<PaymentOption
				title={paypal.title}
				description={paypal.description}
				onSelect={onPaymentOptionSelect}
				selected={selectedPaymentOption?.title === paypal.title}
				img={PayPalLogo}
			/>
			<PaymentOption
				title={creditCard.title}
				description={creditCard.description}
				selected={selectedPaymentOption?.title === creditCard.title}
				onSelect={onPaymentOptionSelect}
				img={CCLogo}
				imgWidth="60px"
			/>
			{selectedPaymentOption?.title === creditCard.title && <CCForm />}
			<ErrorMessageWrapper style={{ marginLeft: "1rem" }}>
				{error}
			</ErrorMessageWrapper>
			<TermsAndConditions />
		</div>
	);
};

const CARD_ELEMENT_OPTIONS = {
	style: {
		base: {
			color: "rgba(17, 17, 19, 0.6)",
			fontFamily: "'Inter', sans-serif",
			fontSmoothing: "never",
			fontSize: "14px",
			"::placeholder": {
				color: "rgba(17, 17, 19, 0.6)",
			},
			":hover": {
				color: "rgba(17, 17, 19, 1)",
				iconColor: "rgba(17, 17, 19, 1)",
			},
			iconColor: "rgba(17, 17, 19, 0.8)",
		},
		invalid: {
			color: "#e74c3c",
			iconColor: "#e74c3c",
		},
	},
};

const CCForm = () => {
	const cursorStyle = {
		cursor: "text",
	};
	return (
		<>
			<Label text="Card Information" />
			<div
				className={CCStyles.input}
				style={{ width: "100%", ...cursorStyle }}
			>
				<CardNumberElement
					options={{ ...CARD_ELEMENT_OPTIONS, showIcon: true }}
				/>
			</div>
			<div
				className={CCStyles.input}
				style={{ width: "50%", ...cursorStyle }}
			>
				<CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
			</div>
			<div
				className={CCStyles.input}
				style={{ width: "50%", ...cursorStyle }}
			>
				<CardCvcElement options={CARD_ELEMENT_OPTIONS} />
			</div>
		</>
	);
};

export default PaymentForm;
