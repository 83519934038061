import React, { useState, useEffect } from "react";
import { Redirect, withRouter } from "react-router";
import { useLicense } from "../context/licenseData-context";
import { wpPost } from "../utils/utils";
import { useLoading } from "../context/loading-context";

/**
 * Check out page container. Parses the license data based on the location and match props (URL), for license URL matching and
 * 	the parsing of the license's data.
 * @component CheckoutPage
 * @see {@link useLicense}
 * @category Views
 * @param {React.ReactChildren} children Component's children
 * @param {object} match React router's match prop
 * @param {object} location React router's location prop
 */
let CheckoutPage = ({ children, match, location }) => {
	const [error, setError] = useState(null);
	const { setLicense } = useLicense();
	const { setIsLoading } = useLoading();
	/**
	 * Parses the license data depending on wether a license key is provided in the URL.
	 * If no data is returned or the license is not valid, the user is redirected back home.
	 */
	useEffect(() => {
		const { params } = match;
		if (params?.licenseKey) {
			/**
			 * Existing license
			 */
			setIsLoading(true);
			const { licenseKey } = params;
			wpPost("get_license_data", "key=" + licenseKey)
				.then((res) => res.json())
				.then(
					(res) => {
						try {
							if (!res || !res?.key) {
								if (res?.error) {
									let error;
									switch (res?.error) {
										// License not found
										case "404":
											error = new Error(
												`Invalid license. Please contact ${process.env.REACT_APP_SUPPORT_EMAIL} for assistance.`
											);
											error.name = "Invalid License";
											break;
										// License is production already
										case "403":
											error = new Error(
												`This license has already been paid and ready to use. Please contact ${process.env.REACT_APP_SUPPORT_EMAIL} for further information.`
											);
											error.name = "License Ready to Use";
											break;
										default:
											error = new Error("");
											error.name = null;
											break;
									}
									throw error;
								} else {
									throw new Error(
										`Invalid license. Please contact ${process.env.REACT_APP_SUPPORT_EMAIL} for assistance.`
									);
								}
							}
							setLicense(res);
						} catch (error) {
							process.env.REACT_APP_DEV_MODE === "true" &&
								console.error("Something went wrong");
							setError({
								message: error.message,
								title: error.name,
							});
						} finally {
							setIsLoading(false);
						}
					},
					(err) => {
						setIsLoading(false);
						process.env.REACT_APP_DEV_MODE === "true" &&
							console.error(err);
						setError({
							message: err.message,
							title: "Something went wrong.",
						});
					}
				);
		} else {
			setError(true);
		}
		// eslint-disable-next-line
	}, []);

	/**
	 * Redirect to Home if retr is true
	 */
	if (error === true) {
		return <Redirect to="/" />;
	} else if (error) {
		return (
			<Redirect
				to={{
					pathname: "/error",
					state: { message: error?.message, title: error?.title },
				}}
			/>
		);
	}

	return <>{children}</>;
};

CheckoutPage = withRouter(CheckoutPage);

export default CheckoutPage;
