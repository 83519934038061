import { windowBreakPoints } from "../data/layout/layout";
import useWindowSize from "./useWindowSize";

/**
 * @module useBreakpoint
 * @category Hooks
 */

/**
 * Window size breakpoint for devices hook. 
 * Returns an object of breakpoints which indicate what type of device is using the app.
 * @category Hooks
 * @see [useWindowSize](module-useWindowSize.html)
 * @function
 * @returns {Array} breakpoints
 * @example
 * // You can get width and height (in px):
 * const [{isXsmall, isSmall, isMedium, isLarge, isXlarge}] = useBreakpoint();
 * console.log(isSmall)  // true
 * console.log(isMedium) // false
 */
const useBreakpoint = () => {
	const { width } = useWindowSize();
	const { xsmall, small, medium, large, xlarge } = windowBreakPoints;

	let breakpoints = {
		isXsmall: width < xsmall,
		isSmall: width < small && width >= xsmall,
		isMedium: width < medium && width >= small,
		isLarge: width < large && width >= medium,
		isXlarge: width >= xlarge,
	};

	const isLessThan = (breakpoint) =>{
		if(breakpoints.hasOwnProperty(breakpoint)){
			let keys = Object.keys(breakpoints);
			let idx = keys.indexOf(breakpoint);
			let res = keys.filter((val, index) => (index <= idx && breakpoints[val]));
			return res.length > 0;
		}
		return false;
	}
	
	const isMoreThan = (breakpoint) =>{
		if(breakpoints.hasOwnProperty(breakpoint)){
			let keys = Object.keys(breakpoints);
			let idx = keys.indexOf(breakpoint);
			let res = keys.filter((val, index) => (index > idx && breakpoints[val]));
			return res.length > 0;
		}
		return false;
	}

	return [breakpoints, isLessThan("isMedium")];
}



export default useBreakpoint;