import { CountryRegionData } from "react-country-region-selector";

/**
 * Utils functions used throughout the application.
 * @module utils
 * @category Utils
 */

/**
 * Format domains for web page displaying.
 * @function
 * @param {*} domains
 * @returns {Array} Domain array, separated by commas.
 * @example
 * const domains = ["google.com","example.com"];
 * let result = formatDomains(domains); //Returns ["google.com,","example.com"];
 */
export const formatDomains = (domains = []) => {
	if (!domains) return;
	let keys = Object.keys(domains);
	return keys.length > 1
		? keys.map((x, idx, arr) => (idx === arr.length - 1 ? x : x + " , "))
		: keys;
};

export const parseDomains = (str) => {
	const json = JSON.parse(str);
	if (!json) {
		return [];
	}
	return Object.keys(json);
};

export function addDays(date, days) {
	var result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
}

export function wpPost(
	action,
	body,
	headers = {
		"Content-type": "application/x-www-form-urlencoded",
	}
) {
	let prefix = process.env["REACT_APP_AJAX_PREFIX"];
	const url = prefix + "/wp-admin/admin-ajax.php?action=" + action;
	return fetch(url, {
		method: "POST",
		headers,
		body,
	});
}

export function reduceObject(object, reducer, init) {
	if (!object || typeof object !== "object") {
		process.env.REACT_APP_DEV_MODE === "true" &&
			console.warn("Non-object for reduceObject is not permitted");
		return null;
	}
	return Object.keys(object).reduce(reducer, init);
}

export function getTouched(touched = {}, fields = []) {
	let someTouched,
		allTouched = false;
	if (!touched) return;
	let keys = Object.keys(touched);

	let filtered = keys.filter((k) => fields.includes(k));
	someTouched = filtered.length > 0;
	allTouched = filtered.length === fields.length;
	return [someTouched, allTouched];
}

// Potential issue with arrays
export function objToUri(obj) {
	return new URLSearchParams(Object.entries(obj)).toString();
}

export function getCountryShortcode(country) {
	let shortcode = CountryRegionData.find((c) => c[0] === country);
	if (!shortcode) {
		throw new Error(
			"Multiple matches for country, input: ",
			country,
			" found: ",
			shortcode,
			typeof shortcode
		);
	}
	return shortcode[1];
}

/* 
    Clean URL to only leave hostname
        EX: http://localhost:8080/ -> localhost
*/
export function extractHostname(url) {
	let hostname;
	//find & remove protocol (http, ftp, etc.) and get hostname
	if (!url) {
		return;
	}
	if (url.indexOf("///") > -1) {
		hostname = url.split("/")[3];
	} else if (url.indexOf("//") > -1) {
		hostname = url.split("/")[2];
	} else {
		hostname = url.split("/")[0];
	}

	//find & remove port number
	hostname = hostname.split(":")[0];
	//find & remove "?"
	hostname = hostname.split("?")[0];

	return hostname;
}

export function capitalizeFirstLetter(str) {
	if (!str) return;
	let first = str[0];
	first = first.toUpperCase();
	return first + str.substring(1);
}

export function flattenObj(obj) {
	if (!obj || typeof obj !== "object") return [];
	let keys = Object.keys(obj);
	let values = Object.values(obj);
	let arr = [];

	for (let i = 0; i < keys.length; i++) {
		const value = values[i];
		const key = keys[i];
		if (Array.isArray(value)) {
			for (let j = 0; j < value.length; j++) {
				const v = value[j];
				arr.push({ key, value: v });
			}
		} else {
			arr.push({ key, value });
		}
	}

	return arr;
}

export function htmlDecode(input) {
	var doc = new DOMParser().parseFromString(input, "text/html");
	var cont = doc.documentElement.textContent.replace(/\\/g, "");
	return cont;
}
