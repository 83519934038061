import React, { useReducer } from "react";

const PaymentContext = React.createContext();

/**
 * @module payment/context
 * @category Context
 */

/**
 * @typedef ReducerAction
 * @description Determines the type of operation and payload of the reducer funciton
 * @property {string} type Type of operation
 * @property {string | object | number | Array} value Payload of the operation
 */

/**
 * Payment reducer function
 * @function
 * @category Context
 * @subcategory Payment
 * @param {object} state Current state
 * @param {ReducerAction} action Incoming action
 */
function paymentReducer(state, action) {
	switch (action.type) {
		case "capture": {
			return { ...state, payment: { result: action.value } };
		}
		case "clear": {
			return { payment: false };
		}
		case "error": {
			return { payment: { error: action.value } };
		}
		case "exception": {
			return { payment: { exception: action.value } };
		}
		default: {
			throw new Error("Unhandled exception type: " + action.type);
		}
	}
}

/**
 * Payment provider component
 * @category Context
 * @subcategory Payment
 * @function
 * @param {React.ReactChildren} children Children components to render
 */
export function PaymentProvider({ children }) {
	const [state, dispatch] = useReducer(paymentReducer, { payment: false });
	const value = { state, dispatch };
	return (
		<PaymentContext.Provider value={value}>
			{children}
		</PaymentContext.Provider>
	);
}

/**
 * Payment provider consumer
 * @category Context
 * @subcategory Payment
 * @function
 * @param {React.ReactChildren} children Children components to render
 */
export function PaymentConsumer({ children }) {
	return (
		<PaymentContext.Consumer>
			{(context) => {
				if (context === undefined) {
					throw new Error(
						"PaymentContext must be used within a PaymentProvider"
					);
				}
				return children(context);
			}}
		</PaymentContext.Consumer>
	);
}

/**
 * Payment context hook
 * @category Context
 * @subcategory Payment
 * @function
 */
export function usePayment() {
	const context = React.useContext(PaymentContext);
	if (context === undefined) {
		throw new Error("usePayment must be used within a PaymentProvider");
	}
	return context;
}
